html,body,#app{
    height: 100%;
    margin: 0;
    padding: 0;
}
.table_class{
 &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      width: 4px;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 6px;
      height: 50px;
      background: rgba(0, 0, 0, 0.2);
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      background: #eee;
    }
  }
 
}